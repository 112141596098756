import React, { ReactNode, useMemo, useState } from 'react';
import { captureException as captureExceptionToSentry } from '@sentry/gatsby';
import { StatsigClient } from '@statsig/js-client';
import { StatsigProvider } from '@statsig/react-bindings';

type StatsigClientRootProviderProps = {
  children: ReactNode;
};

/**
 * Initializes Statsig client and makes it accessible
 *
 * @see https://docs.statsig.com/client/javascript-sdk/react for more information
 *
 * todo: there are parts of the application which uses older statsig-js package.
 *  This uses the newer @statsig/js-client and @statsig/react-bindings.
 *  The older parts should be treated as deprecated and removed.
 * */
export default function StatsigClientRootProvider(
  props: StatsigClientRootProviderProps
) {
  const [isLoading, setIsLoading] = useState(true);

  const statsigClient = useMemo(() => {
    const statsigClientInstance = new StatsigClient(
      process.env.GATSBY_STATSIG_SDK_KEY,
      {},
      { environment: { tier: process.env.GATSBY_STATSIG_ENVIRONMENT_TIER } }
    );

    statsigClientInstance
      .initializeAsync()
      .then(() => setIsLoading(false))
      .catch(captureExceptionToSentry)
      .finally(() => setIsLoading(false));

    return statsigClientInstance;
  }, []);

  if (isLoading) return null;

  return (
    <StatsigProvider client={statsigClient}>{props.children}</StatsigProvider>
  );
}
