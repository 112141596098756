import React from 'react';
import { Script } from 'gatsby';

export default function HyrosScript() {
  return <Script dangerouslySetInnerHTML={{ __html: hyrosInlineScript }} />;
}

const hyrosInlineScript = `
  var head = document.head;
  var script = document.createElement('script');
  script.type = 'text/plain';
  script.setAttribute('data-cookieconsent', 'marketing');
  script.src = "https://t.leyaai.com/v1/lst/universal-script?ph=567af89324eebe7f75399ae354ad8a54a062d8949a01ae2d4793ca5a10f037b4&tag=!clicked&ref_url=" + encodeURI(document.URL) ;
  head.appendChild(script);
`;
