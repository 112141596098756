import React from 'react';
import { GatsbyBrowser } from 'gatsby';

import { MODAL_ROOT_ID } from 'utils/constants';

import StatsigClientRootProvider from '../../../experiments/StatsigClientRootProvider';
import HyrosScript from '../../scripts/HyrosScript';

import StateProvider from './StateProvider';
import ThemeProvider from './ThemeProvider';
import { useQueryParams } from './hooks';

import type { BasePageContext } from 'utils/gatsby/types';

const Page: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props: pageProps,
}) => {
  useQueryParams(pageProps);

  return (
    <StateProvider
      pageContext={pageProps.pageContext as unknown as BasePageContext}
    >
      <ThemeProvider>
        <HyrosScript />
        <StatsigClientRootProvider>
          <div id={MODAL_ROOT_ID} />
          {element}
        </StatsigClientRootProvider>
      </ThemeProvider>
    </StateProvider>
  );
};

export default Page;
